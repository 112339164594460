<template>
  <div class="main-container citizen-charter-detail-wrapper" v-if="details">
      <div class="card citizen-charter-detail__left">
        <vue-custom-scrollbar class="scroll-area">
          <h2>योजना सम्झौता</h2>
          <dl>
            <dt><unicon name="money-stack"></unicon>सेवा शुल्क</dt>
            <dd>{{ details.servicecharge || '-' }}</dd>
            <dt><unicon name="clock-three"></unicon>लाग्ने समय</dt>
            <dd>{{ details.serviceduration || '-' }}</dd>
            <dt><unicon name="building"></unicon>सेवा प्रदान गर्ने कार्यालय</dt>
            <dd>{{ details.serviceprovider || '-' }}</dd>
            <dt><unicon name="university"></unicon>जिम्मेवार शाखा</dt>
            <dd>{{ details.responsiblebranch || '-' }}</dd>
            <dt><unicon name="estate"></unicon>कोठा नं</dt>
            <dd>{{ details.roomnumber || '-' }}</dd>
            <dt><unicon name="phone-alt"></unicon>सम्पर्क नं</dt>
            <dd>{{ details.contactnumber || '-' }}</dd>
            <dt><unicon name="comment-alt-message"></unicon>सेवा सम्बन्धित गुनासो सुन्ने जिम्मेवार अधिकारी</dt>
            <dd>{{ details.servicegrievanceofficial || '-' }}</dd>
            <dt><unicon name="process"></unicon>सेवा सम्बन्धित गुनासो सुन्ने प्रक्रिया</dt>
            <dd>{{ details.servicegrievanceprocedure || '-' }}</dd>
            <dt><unicon name="notes"></unicon>सम्बन्धित सेवा प्राप्त गर्न भर्नु पर्ने फारम</dt>
            <dd>{{ details.serviceformlink || '-' }}</dd>
          </dl>
        </vue-custom-scrollbar>
      </div>
      <div class="citizen-charter-detail__right">
        <div class="card">
          <h3><unicon name="process"></unicon>प्रक्रिया / सेवा सुबिधा प्राप्त गर्न आवश्यक पर्ने प्रक्रियाहरु</h3>
          <ol v-if="details.serviceprocedure">
            <li v-for="(ele, index) in details.serviceprocedure.split('\n')" :key="index">{{ ele }}</li>
          </ol>
        </div>
        <div class="card">
          <h3><unicon name="file-edit-alt"></unicon>आवश्यक पर्ने कागजातहरु</h3>
          <ol v-if="details.requireddoc">
            <li v-for="(ele, index) in details.requireddoc.split('\n')" :key="index">{{ ele }}</li>
          </ol>
        </div>
      </div>
    </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  data() {
    return {
      details: {}
    };
  },
  components: {
    vueCustomScrollbar
  },
  computed: {
    citizenCharter() {
      return this.$store.state.citizenCharter;
    }
  },
  watch: {
    citizenCharter: {
      handler: "getDetails",
      immediate: true
    }
  },
  methods: {
    getDetails() {
      let id = this.$route.params.id;
      this.details = this.$store.state.citizenCharter.find((ele) => {
        return (ele.id === id);
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.citizen-charter-detail-wrapper {
  background-color: #05050f;
  display: flex;
  height: calc(100vh - 300px);
  justify-content: space-between;
  padding: 0 100px;

  @media screen and (max-width: 3199px) {
    padding: 28px 28px;
    height: calc(100vh - 108px);
  }
}

.card {
  background-color: $neutrals-charcoal;
  border-radius: 20px;
  margin-bottom: 40px;
  padding: 64px 80px;

  @media screen and (max-width: 3199px) {
    padding: 22px 28px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  ol li {
    padding-bottom: 48px;

    @media screen and (max-width: 3199px) {
      padding-bottom: 14px;
    }
  }
}

.citizen-charter-detail__left,.citizen-charter-detail__right {
  color: $neutrals-white;
  width: calc(100% - 20px);

  @media screen and (max-width: 3199px) {
    width: calc(100% - 8px);
  }
}

.citizen-charter-detail__left {
  margin-right: 20px;

  @media screen and (max-width: 3199px) {
    margin-right: 8px;
  }

  dl {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  dt,dd {
    line-height: 72px;
    margin-bottom: 48px;
    width: 40%;

    @media screen and (max-width: 3199px) {
      margin-bottom: 20px;
      line-height: 26px;
    }
  }

  dt {
    align-items: flex-start;
    display: flex;
    padding-right: 60px;

    @media screen and (max-width: 3199px) {
      padding-right: 14px;
    }
  }
}

.citizen-charter-detail__right {
  margin-left: 20px; 

  @media screen and (max-width: 3199px) {
    margin-left: 8px;
  }

  h3 {
    align-items: flex-start;
    display: flex;
    font-weight: 600;
    margin-bottom: 40px;

    @media screen and (max-width: 3199px) {
      margin-bottom: 14px;
    }
  }
}

</style>
